.logotype{
  display: flex;
  align-items: center;
  min-width: 208px;
}

.logotype_image{
  width: 46px;
  height: 46px;
}

.logotype_name{
  padding: 0px 14px;
  font-family: Jura;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.1px;
  color: #000000;
}
@media (max-width: 600px){
  .logotype_name{
    padding: 0px 7px;
    font-size: 14px;
  }
  .logotype{
    float: left;
  }
}