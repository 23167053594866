.color-success-disabled{
  color: rgba(24, 108, 74, 1) !important;
  font-family: 'InterBold' !important;
}
.text-success{
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 35px;
  letter-spacing: -0.1px;
  color: rgba(24, 108, 74, 1);
}
.text-error{
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 35px;
  letter-spacing: -0.1px;
  color: rgba(235, 94, 85, 1);
}
.text-primary{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: rgba(43, 39, 40, 0.6);
}
.text-bold{
  font-family: 'InterBold';
  font-weight: bold;
}
.text-bold-black{
  font-family: 'InterBlack';
  font-weight: bold;
}

.header{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #000;
}

.greeting-page{
  width: 100%;
}
.greeting-main{
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 20px;
}

.private-main{
  display: flex;
  min-height: calc(100vh - 75px - 56px);
 /* min-height: 100vh;*/
}

.p-button.button-success{
  border-radius: 10px;
  background-color: rgba(32, 144, 99, 1);
}
.p-button.button-success span{
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #FFFFFF;
}

.p-button.button-success:not(a):not(.p-disabled):active {
  background: #2AC084;
  color: #ffffff;
  border-color: #2AC084;
}
.p-button.button-success:not(a):not(.p-disabled):hover {
  background: #2AC084;
  color: #ffffff;
  border-color: #2AC084;
}

.p-button.button-primary{
  border-radius: 10px;
  background-color: #3a3436;
}
.p-button.button-primary-out{
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #EDE8E9;
  color: #3A3335;
  padding: 0rem 1rem;
}
.p-button.button-primary span{
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #FFFFFF;
}
.p-button.button-primary-out span{
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.1px;
}

.p-button.button-outline{
  background: none;
  border: none;
  border-radius: 10px;
  padding: 0rem 1rem;
  color: #908E8E;
}

.p-button.button-outline:not(a):not(.p-disabled):active {
  background: #908E8E;
  color: #3a3335;
  border-color: #ede8e9;
}
.p-button.button-outline:not(a):not(.p-disabled):hover {
  background: #ede8e9;
  color: #3a3335;
  border-color: #ede8e9;
}

.button-outline span{
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.1px;
}

.p-datatable .p-datatable-footer, .p-datatable .p-datatable-thead>tr>th {
  background: white;
  color: #908E8E;
  border: none;
  font-family: 'Inter';
  font-size: 12px;
  height: 63px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-right: .15rem;
  font-size: 12px;
}

.p-datatable .p-sortable-column {
  direction: rtl;
}

.p-datatable .p-sortable-column.p-highlight {
  background: none;
  color: #2AC084;
}

.p-button.button-primary-out:not(a):not(.p-disabled):active {
  background: #EDE8E9;
  color: #3A3335;
  border-color: #EDE8E9;
}
.p-button.button-primary-out:not(a):not(.p-disabled):hover {
  background: #EDE8E9;
  color: #3A3335;
  border-color: #EDE8E9;
}

.p-button.button-primary:not(a):not(.p-disabled):active {
  background: #2c282981;
  color: #ffffff;
  border-color: #2c282981;
}
.p-button.button-primary:not(a):not(.p-disabled):hover {
  background: #2c282981;
  color: #ffffff;
  border-color: #2c282981;
}

.headerPage{
  padding: 1rem;
}

.headerPage h1{
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.0857143px;
  color: rgba(43, 39, 40, 0.6);
  margin: 0;
}

.headerPage p{
  font-family: 'InterBold';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.0857143px;
  color: #3A3335;
  margin: 0;
}

/* pro-sidebar */
/***********************/
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
  font-size: 18px;
}
.pro-sidebar {
  color: #3A3335 !important;
  box-shadow: 0px 20px 24px rgba(153, 155, 168, 0.15);
}
.pro-sidebar .pro-menu a {
  color: #3A3335 !important;
}
.pro-sidebar>.pro-sidebar-inner {
  background: #ffffff !important;
}
.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  border-right: 1px solid rgba(58, 51, 53, 0.12);
}
.pro-sidebar .overlay {
  background-color: rgba(0,0,0,.0) !important;
}
/* .pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  background-color: #fff !important;
} */
.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item{
  opacity: 0;
  background-color: #fff !important;
}
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  
  background-color: #fff !important;
}
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover>.pro-inner-list-item {
  transition: opacity .3s ease;
  opacity: 1;
}
.react-slidedown {
  opacity: 1 !important;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner{
  border: 1px solid rgba(58, 51, 53, 0.12);
}
.pro-sidebar.collapsed .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  left: 1px !important;
}
/* .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  visibility: hidden;
} */
.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: #fff !important;
}

.pro-sidebar .pro-menu a:hover,
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover,
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
  color: #2AC084 !important;
  border-bottom: 1px solid #2ac084;
}

.pro-menu-item.pro-sub-menu:hover {
  color: #2AC084 !important;
  border-bottom: 1px solid #2ac084;
}
/***********************/

/* dialog */
/***********************/
.p-dialog .p-dialog-header {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.p-dialog .p-dialog-content {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.p-dialog .p-dialog-footer {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.p-dialog.p-confirm-dialog .p-dialog-content {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-dialog {
  border-radius: 12px;
}
/***********************/

.route-table > .p-datatable .p-datatable-tbody>tr>td {
  font-family: 'Inter';
  width: 7vw;
  text-align: center;
}
.route-table > .p-datatable .p-datatable-footer, .route-table > .p-datatable .p-datatable-thead>tr>th {
  width: 7vw;
}

.card{
  padding-bottom: 24px;
  height: calc(100vh - 300px);
}
.pro-sidebar-content{
  height: calc(100vh - 75px);
}
.fa-w-14, .fa-w-20{
  cursor:pointer;
}
.p-frozen-column{
  background: #f8f9fa!important;
}
.route-table .p-datatable-tbody>tr>td{
  white-space: break-spaces!important;
}
.route-table th{
  white-space: break-spaces!important;
}
.p-editable-column{
  /* padding-top: 0!important;
  padding-bottom: 0!important; */
}
.p-editable-column input{
 /* width: 100%;
 height: 100%; */
}
.p-datatable .p-column-header-content{
display:block!important;
}
.page-main{
  overflow: auto;
}
.fa-w-20:hover{
  color:#2ac084;
}
.fa-w-14:hover{
  color:#2ac084;
}

.p-toast .p-toast-message {
  background: #ffffff !important;
}

.p-dialog.p-component.p-dialog-enter-done{
  min-width: 375px;
}

.table-column--setting{
  border-radius: 50%;
  border: 1px solid #209063;
  background: none;
  color: #209063;
}

.p-menuitem-text{
  font-size: 14px;
}

@media (max-width: 375px){

  .p-toast {
    width: 22rem !important;
  }
  
  .p-toast-top-right {
    top: 10px !important;
    right: 10px !important;
  }
}

@media (max-width: 768px){
  .card{
    padding-bottom: 24px;
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
  .route-table th{
    padding-left:3px!important;
    padding-right:3px!important;
    text-align: center;
  }
}