.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.color-danger {
  color: rgba(235, 94, 85, 1)
}

.color-success {
  color: #2AC084
}

.filter-blur{
  filter: blur(1px);
}


.main-page{
  width: 100%;
  /* height: calc(100vh - 75px - 75px) */
}

.page-main{
  width: 100%;
  /* height: 100%; */
}

.Drivers, .License, .Routes{
  width: 100%;
/*height: 100%; */
  height: auto;
  overflow: hidden
}

.emptyPage{
  width: 100%;
  height: calc(100% - 140px);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 524px;
}

.emptyPage-description{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10%;
}

.emptyPage-description h1{
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.0944444px;
  color: #3A3335;
  margin: 5px 0;
}

.emptyPage-description span{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #908E8E;
  margin: 12px 0;
  display: inline-block;
  max-width: 50%;
}
.emptyPage-description > .emptyPage-btn span{
  max-width: 100%;
  margin: 0px;
}

.wrapper-spinner {
  filter: blur(1px);
  height: calc(100vh - 135px - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bids-column{
  font-size: 24px;
  text-align: center;
  font-family: 'Jura';
  font-weight: 800;
}

.confirm-dialog-content{
  padding-bottom: 1.5rem;
}

.confirm-dialog-delete .confirm-dialog-content .p-confirm-dialog-icon{
  color: rgba(235, 94, 85, 1);
  font-size: 3rem;
}

.confirm-dialog-delete .p-confirm-dialog-message{
  padding: .5rem;
  font-family: "InterBold";
}

.confirm-dialog-delete .confirm-delete-reject{
  color: #fff;
  background-color: #2AC084;
  border: 1px solid #2AC084;
}

.confirm-dialog-delete .confirm-delete-accept{
  background-color: #ffffff;
  color: #eb5e55;
  border: 1px solid #eb5e55;
}

.confirm-dialog-delete .confirm-delete-accept:focus{
  box-shadow: 0 0 0 0.2rem rgba(235, 94, 85, 1);
}

.confirm-dialog-delete .confirm-delete-reject:focus{
  box-shadow: 0 0 0 0.2rem #2AC084;
}

.confirm-dialog-delete .confirm-delete-accept:hover{
  background-color: rgb(241, 70, 58);
  border: 1px solid rgb(241, 70, 58);
}

.confirm-dialog-delete .confirm-delete-reject:hover{
  background-color: rgba(24, 108, 74, 1);
  border: 1px solid rgba(24, 108, 74, 1);
}

.window-title{
  font-family: 'Inter';
  font-weight: 800;
  font-size: 14px;
}

.window-description-mini{
  font-size: 12px;
  font-family: "Inter";
  line-height: 14px;
  font-style: italic;
}

@media (max-width: 700px){
  .emptyPage-description img{
    height: 200px;
  }
  .emptyPage-description span {
    max-width: 90%;
  }
  .emptyPage-description h1 {
    font-size: 18px;
    line-height: 28px;
  }
  .emptyPage-description span {
    margin: 0px 0;
    max-width: 90%;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
